<template>
  <div class="table-filters" :class="{ __sticky: isSticky }">
    <div class="table-filters__desktop">
      <base-date-picker
        type="date"
        label="Дата нач."
        :value="selectedDateBegin"
        @change="v => (selectedDateBegin = v.target.value)"
      />
      <base-date-picker
        type="date"
        label="Дата кон."
        :value="selectedDateEnd"
        @change="v => (selectedDateEnd = v.target.value)"
      />
      <people-selector
        v-if="canChangePeople"
        label="Сотрудник"
        filter="work"
        :id_peopleIn="selectedPeopleId"
        @change="v => (selectedPeopleId = v)"
      />
      <point-selector
        v-if="canChangePoint"
        label="Точка"
        :point-id="selectedPoint?.id || pointId"
        @change="v => (selectedPoint = v)"
      />
      <base-chip v-if="totalHours">Часов: {{ totalHours > 0 ? totalHours : 0 }}</base-chip>
      <base-button is-new primary small @click="onSubmit">Применить</base-button>
    </div>
    <div v-if="!showMobileFilter" class="table-filters__mobile __info">
      <div class="table-filters__info">
        <div class="table-filters__point h3">{{ selectedPoint?.nickname }}</div>
        <div class="table-filters__range h4 color-neutral-600">{{ datePeriod }}</div>
        <div class="table-filters__total h4 mt-1" v-if="totalHours">Часов: {{ totalHours > 0 ? totalHours : 0 }}</div>
      </div>
      <base-icon-button icon-path="filter-grey.svg" medium outline width="20" height="20" @click="openFilter" />
    </div>
    <div v-else class="table-filters__mobile __filter">
      <div class="table-filters__settings">
        <div class="table-filters__filter-wrapper">
          <div class="table-filters__title">Наcтройка</div>
          <base-date-picker
            type="date"
            label="Дата нач."
            :value="selectedDateBegin"
            @change="v => (selectedDateBegin = v.target.value)"
          />
          <base-date-picker
            type="date"
            label="Дата кон."
            :value="selectedDateEnd"
            @change="v => (selectedDateEnd = v.target.value)"
          />
          <people-selector
            v-if="canChangePeople"
            label="Сотрудник"
            filter="work"
            :id_peopleIn="selectedPeopleId"
            @change="v => (selectedPeopleId = v)"
          />
          <point-selector
            v-if="canChangePoint"
            label="Точка"
            :point-id="selectedPoint?.id || pointId"
            @change="v => (selectedPoint = v)"
          />
        </div>
        <div class="table-filters__button-wrapper">
          <base-button is-new tertiary extra-small @click="closeFilter">Отмена</base-button>
          <base-button is-new primary extra-small @click="onSubmit">Применить</base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { dateFormatJS, dateFormatVarious } from "@/components-js/dateFormat";
import BaseDatePicker from "@/components/BaseDatePicker";
import BaseChip from "@/components/BaseChip";
import BaseButton from "@/components/BaseButton";
import BaseIconButton from "@/components/BaseIconButton";
import PeopleSelector from "@/components/PeopleSelector.vue";
import PointSelector from "@/components/PointSelector.vue";

const props = defineProps({
  dateBegin: { type: String | Date, default: new Date() },
  dateEnd: { type: String | Date, default: new Date() },
  canChangePeople: { type: Boolean },
  canChangePoint: { type: Boolean },
  peopleId: { type: String | Number | undefined },
  pointId: { type: String | Number | undefined },
  totalHours: { type: Number },
  isSticky: { type: Boolean },
});

const emits = defineEmits(["submit"]);

const route = useRoute();
const router = useRouter();
const selectedDateBegin = ref(dateFormatJS(props.dateBegin));
const selectedDateEnd = ref(dateFormatJS(props.dateEnd));
const selectedPeopleId = ref(props.peopleId);
const showMobileFilter = ref(false);
const selectedPoint = ref({});

const datePeriod = computed(
  () => `${dateFormatVarious(selectedDateBegin.value)} - ${dateFormatVarious(selectedDateEnd.value)}`
);

const onSubmit = () => {
  showMobileFilter.value = false;
  haveflow();
  emits("submit", {
    dateBegin: selectedDateBegin.value,
    dateEnd: selectedDateEnd.value,
    ...(props.canChangePeople ? { peopleId: selectedPeopleId.value } : {}),
    ...(props.canChangePoint ? { pointId: selectedPoint.value?.id } : {}),
  });
  router.push({ name: "schedule" });
};

const noflow = () => {
  document.body.style.overflow = "hidden";
  document.body.style.userSelect = "none";
};

const haveflow = () => {
  document.body.style.overflow = "auto";
  document.body.style.userSelect = "auto";
};

const openFilter = () => {
  showMobileFilter.value = true;
  noflow();
  router.push({ name: "schedule-settings" });
};

const closeFilter = () => {
  showMobileFilter.value = false;
  haveflow();
  router.push({ name: "schedule" });
};

const handlePopState = () => {
  haveflow();
  showMobileFilter.value = false;
};

onMounted(() => {
  window.addEventListener("popstate", handlePopState);
  if (showMobileFilter.value === false && route.name === "schedule-settings") {
    router.push({ name: "schedule" });
  }
});

onUnmounted(() => {
  window.removeEventListener("popstate", handlePopState);
});
</script>

<style lang="scss" scoped>
@import "@/styles/ui/breakPoints";
@import "@/styles/ui/fontSize";
.table-filters {
  background: var(--color-basic-white);

  &.__sticky {
    position: sticky;
    top: 0;
  }

  &__desktop {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: var(--sp-6);
  }

  &__mobile {
    display: none;
  }

  &__total {
    color: var(--color-neutral-600);
  }

  @include mobile-only {
    &__desktop {
      display: none;
      flex-direction: column;
      align-items: unset;
    }

    &__mobile {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 0;

      &.__info {
        padding: 0 var(--sp-4);
      }

      &.__filter {
        justify-content: unset;

        .table-filters {
          &__info {
            display: flex;
            flex-direction: row;
            gap: var(--sp-6);
            width: 100%;

            > div {
              width: 100%;
            }
          }
        }
      }
    }

    &__settings {
      position: fixed;
      width: 100%;
      left: 0;
      top: 48px;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px;
      background: white;
      gap: var(--sp-6);
    }

    &__title {
      @include add-font-face("h2");
    }

    &__filter-wrapper {
      display: flex;
      flex-direction: column;
      gap: var(--sp-6);
    }

    &__button-wrapper {
      display: flex;
      flex-direction: row;
      gap: var(--sp-3);
      width: 100%;
      padding-top: var(--sp-3);
      border-top: 1px solid var(--color-neutral-200);

      > div {
        width: 100%;
      }
    }
  }
}
</style>
