<template>
  <div>
    <div class="high" style="display: inline-grid; width: 100%">
      <base-select
        :label="label"
        :value="pointCurrent?.id"
        :option-list="pointList"
        @change="handleChange($event.target.value)"
      />
    </div>
  </div>
</template>

<script setup>
import { request } from "@/components-js/requestSrv";
import BaseSelect from "@/components/BaseSelect";
import { onBeforeMount, ref, computed } from "vue";
const emits = defineEmits(["change"]);

const props = defineProps({
  label: String,
  pointId: [Number, String],
  allPointsFlag: Boolean,
  disabled: String,
});

const pointList = ref(props.allPointsFlag ? [{ id: "%", nickname: "Все точки" }] : []);
const pointCurrent = computed(
  () => pointList.value?.find(item => Number(item.id) === Number(props.pointId)) ?? pointList.value[0]
);

const handleChange = value => {
  emits(
    "change",
    pointList.value?.find(item => Number(item.id) === Number(value))
  );
};

onBeforeMount(async () => {
  pointList.value = [...pointList.value, ...(await request("/api/point/list", "GET"))];
  emits("change", pointList.value?.find(item => Number(item.id) === Number(props.pointId)) ?? pointList.value[0]);
});
</script>
<style lang="scss" scoped>
.point-selector {
  margin-top: 12px;
  padding-left: 10px;

  @media (max-width: 980px) {
    margin-top: 6px;

    &__label {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }
  }
}
</style>
